import React from 'react'
import Consultation from './consultation.js'
import Navbar from '../header/Navbar.js'
import Testimonies from './Testimonies';
import { useNavigate } from 'react-router-dom';
import Footer from '../footer/Footer.js';


import './componentsStyles.css';




const Home = () => {


 const navigate = useNavigate();
 const aboutUs = () => navigate('/about');


const handleClick = () => {
    window.open("https://drchrono.com/scheduling/offices/dGhpcyBpcyAxNiBjaGFyc6a8FJNMnMYLsC2WRxAfig4=")
   }




 return (
   <>
   <Consultation />
   <Navbar />
   <div className='home-images'>
     <div className='home-text'>
       <h1>Sleep Well Live Better</h1>
       {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p> */}
       <button onClick={aboutUs}>About Us</button>
     </div>
     </div>
     <div className='home-info'>
      <div className='home-info-content'>
     <h1> Welcome to Better Sleep Health</h1>
     <h2>Take a sleep test at home</h2>
     <h3>We'll ship you an FDA-approved sleep testing device directly to your doctor</h3>
     <ul>
      <li>Skip the lab and complete the sleep test from the comfort of your own bed</li>
      <li>Ensure accuracy with three nights of sleep data</li>
      <li>Return the device at your convenience with the provided return label</li>
      </ul>
      <h2>Meet your sleep doctor online</h2>
      <p>All of our doctors are fellowship-trained and/or board-certified in Sleep Medicine. Your Empower Sleep
        doctor will analyze your sleep test data and meet with you 1:1 to discuss the results and treatment
        options. Visits are done virtually through your phone or computer.</p>
       <p className='paragraph-1'></p>
       <button onClick={aboutUs}>About Us</button>
       </div>
     </div>
     <div className='home-providers'>
       <div className = 'home-providers-info'>
         <div className="home-providers-info-content">
         <h1> Get personalized prescription treatment shipped to your door</h1>
         <p>No need to search around for different treatment options. With Empower Sleep, Continuous Positive
Airway Pressure (CPAP) machines are shipped to your door and/or medications are prescribed to your
local pharmacy. If you need to see a dentist for an oral appliance, we’ll help you find a local specialist.</p>
         </div>
       </div>
       <div className='home-practiceinfo'>
         <h1>3<span>LOCATIONS</span></h1>
         <h1>9<span>CONSECUTIVE YEARS AS TOP DOC</span></h1>
         <h1>90%+<span>CPAP COMPLIANCE RATE</span></h1>
       </div>
     </div>
     <div className='home-consultation-wrapper'>
           <div className='home-consultation'>
             <h1>Schedule your Consultation</h1>
             <p>Begin your journey today at Better Sleep Health</p>


             <div className='home-consultation-button'>
                 <button onClick={handleClick}>GET STARTED TODAY</button>
             </div>
           </div>
       </div>
     <Footer/>


   </>
 )
}


export default Home
