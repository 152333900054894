import React from 'react'
import Navbar from '../header/Navbar'




const Payment = () => {
 return (
   <Navbar/>
 )
}


export default Payment