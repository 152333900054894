import React from 'react'
import Navbar from '../header/Navbar'
import Consultation from './consultation.js';


const Blog = () => {
  return (
    <><Consultation/>
    <Navbar />
    <div></div></>
  )
}

export default Blog;
